@import "../../styles/variables";

.site-footer{
   position: fixed;
   bottom: 0;
   left: 0;
   width: 100%;
   background-color: var(--primary-color);
   font-size: 12px;
   z-index: 100;
    @media (max-width: 767px) {
        display: none;
    }
  .container {
    margin-bottom: 0;
  }
   .content-wrapper{
        padding: 20px 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 575px) {
            padding: 20px;
        }
    }

   .footer-links{
       ul{
            list-style: none;
            display: flex;
            padding: 0;
            margin: 0;
            li{
                text-transform: uppercase;
                &:not(:last-child) {
                    margin-right: 20px;
                }
               a {
                font-weight: 700;
                    color: #ffffff;
                   &:hover{
                       color: var(--secondary-color);
                   }
               }
            }
        }
   }
   .footer-copyright {
        font-weight: 700;
        color: #ffffff;
        text-transform: uppercase;
   }

   .footer-images{
       img{
            height: 35px;
            margin-left: 20px;
            @include break-md{
                height: 25px;
                margin-left: 0;

                &.middle{
                    margin: 0 20px;
                }
            }
       }
   }

  
   
}
