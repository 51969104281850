.card {
  margin-top: 10px !important;
  padding: 15px;

  .create-chat-group {
    .row-form-control {
      display: flex;
      align-items: center;

      .avatar-box {
        width: 80px;
        height: 80px;
        margin-right: 20px;
        display: flex;
        overflow: hidden;
        background-color: #f7f7f7;
        align-items: center;
        justify-content: center;
        i {
          font-size: 40px;
        }
        .form-group {
          margin-bottom: 0;
          .upload-list {
            border-bottom: 0;
            .upload-list-media {
              .single-media {
                width: 80px;
                height: 80px;
                img {
                  height: 100%;
                  width: 100%;
                }
                .btn {
                  .iconleftspan {
                    i {
                      font-size: 25px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .added-users {
    display: flex;
    // flex-direction: column;
    align-items: center;
    height: 100px;
    overflow-y: auto;
    .img-cont {
      // display: flex;
      // flex-direction: column;
      position: relative;
      img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 5px;
        margin-right: 20px;
        border: 1px solid #707271;
      }
      i {
        position: absolute;
        right: 10px;
        bottom: -10px;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
  .list {
    max-height: calc(100vh - 470px);
    overflow: auto;
    @media (max-width: 767px) {
      max-height: calc(100vh - 410px);
    }
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.3);
    }
    .user-card {
      padding: 10px 0px;
    }
    .primary {
      color: var(--secondary-color);
    }
  }
}

.topHeader {
  display: flex;
  justify-content: space-between;
  .btn-cont {
    // margin-bottom: 15px;
      margin-top: 15px;
    //   display: flex;
    //   width: 100%;
    //   justify-content: center !important;
  }
}
.imageText{
  max-width: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;

}
.back-btn-cont {
  padding: 0px;
  margin-top: 20px !important;
}

.addParty{
  color:var(--primary-color)
}
// .avatar-box{
//   position: absolute;
//   .groupMediaUpload{
//     .upload-list-media{
//       .single-media{
//         // position: fixed;
//         img{

//         }
//         button{
//           position: relative;
//           top: 0;
//           right: 0;
//         }
//       }
//     }
//   }
// }
