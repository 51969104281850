@import "../../styles/variables";

.modal-content {
  overflow: hidden;
  .user-details {
    padding: 15px 0 !important;
    min-height: 300px;

    .user-details-header {
      display: flex;
      align-items: center;

      .header {
        line-height: 1.3;
      }
      img {
        border-radius: 50%;
        width: 60px;
        height: 60px;
        object-fit: cover;
        margin-right: 20px;
        box-shadow: 0 0 10px rgba(black, 0.6);
      }

      .name {
        font-size: 20px;
        color: var(--primary-color);
        display: block;
      }

      .plz {
        font-size: 16px;
        color: var(--primary-color);
        display: block;
      }

      .member {
        font-size: 12px;
      }

      .lower {
        span {
          font-size: 12px;
          padding-right: 6px;
        }
      }
    }

    .actions {
      flex-direction: column;
      align-items: flex-start;

      a,
      button {
        margin-top: 20px;
        margin-left: 10px;
        span {
          text-transform: initial !important;
          font-size: 16px;
          font-weight: normal;
        }
      }

      i {
        margin-right: 15px;
      }
    }

    .border-bottom {
      padding-top: 20px;
      border-color: $__color-border;
      margin: 0 -2rem;
      width: calc(2px + 100%);
    }

    .about-me {
      .info {
        line-height: 1.3;
        margin-bottom: 10px;
        display: flex;
        span {
          display: inline-block;
          &:first-child {
            padding-right: 20px;
            min-width: 140px;
          }
        }
      }
    }
  }
}

.primaryColor {
  color: var(--primary-color) !important;
  // cursor: pointer;
}

.dynamiczindex {
  z-index: 1;
}

.card-header{
  top: 0;
  left: 0;
}
