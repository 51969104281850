@import "../../../styles/variables";

.modern-radio-container {
    margin: 10px 0;
    display: flex;
    cursor: pointer;
    user-select: none;
  }
  
  .radio-outer-circle {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border: 2px solid var(--primary-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    transition: all 0.1s linear;

    &.customradio { 

    width: 21px;
    height: 21px;
    }
  }
  
  .radio-inner-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--primary-color);
    transition: all 0.1s linear;
  }
  .unselected {
    border: 2px solid $__color-text;
  }
  .unselected-circle {
    width: 0;
    height: 0;
  }
  .helper-text {
    
    padding-right: 8px;
    font-size: 12px;

    &.customradio {
      font-weight: bold;
      font-size: 15px!important;
    }
  }