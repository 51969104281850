@import "../../styles/variables";

.upload-list-media {
  position: relative;
  

  img,
  video {
    max-width: 100%;
    width: 100%;
    object-fit: cover;
  }

  .single-media {
    position: relative;
    
    img{
      margin-top: 4px;
      border-radius: 5px !important;
    }

    .btn {
      position: absolute;
      top: 0px;
      right: 0px;
      i {
        color: black;
      }
    }
  }
}
.label-upload {
  width: 100%;
  color: var(--primary-color);
  font-size: 16px;
  display: flex;
  align-items: center;
  i {
    margin: 10px 0 10px auto;
  }

  span {
    flex: 1 1 auto;
    margin-left: 5px;
  }

  &.bordered {
    border-bottom: 2px solid var(--primary-color);
    // margin-bottom: 5px;
  }
}

.icon-right {
  i {
    float: right;
  }
}

.upload-list-documents {
  > span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    > span {
      display: flex;
      align-items: center;
    }
  }
}

.image-preview {
  .modal-content {
    .modal-body {
      .single-media-container {
        display: flex;
        width: 100%;
        justify-content: center;

        img {
          max-height: 500px;
          max-width: 500px;
          object-fit: cover;
        }
      }

      .caption {
        margin-top: 20px;
      }
    }
  }
}

.carousel {
  .slider-wrapper {
    padding: 0;
    ul {
      li {
        img {
          // max-width: 350px!important;
          height: 350px !important;
          object-fit: contain !important;
          width: 100% !important;
          // @media (max-width:575px) {
          //   height: inherit!important;
          // }
        }
      }
    }
  }

    .thumbs-wrapper {
      text-align: center;
      .thumbs {
        padding-left: 0;
        overflow: auto;
        width: 100%;
        transform: translate3d(0, 0px, 0px) !important;
      }
    //   ul {
    //     li {
          img {
            width: 50px !important;
            height: 50px !important;
            object-fit: contain !important;
            @media (max-width:575px) {
              width: 100%!important;
            }
          }
        }
    //   }
    // }
  }

  .miw-150 {
    min-width: 150!important;
  }

  .MuiPopper-root {
    z-index: 99999 !important;
  }
