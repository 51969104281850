$__color-primary: #212427;
$__color-secondary: #95b83b;
// $__color-secondary: #FBBE2F; // BAU APP

$__color-secondary-lighten-4: lighten($__color-secondary,40%);
$__color-secondary-lighten-5: lighten($__color-secondary,48%);

$__color-primary-lighten-4: lighten($__color-primary,40%);
$__color-primary-lighten-5: #f6f6f6;

$__color-body: #F3F3F3;
$__color-text: #212427;
$__color-text-secondary: #6a6d6f;
$__color-border: #eee;

$__color-bubble: #DAE3E2;
$__color-disabled: #e9e9e9;

$extra-large-device-width: 1364px;
$large-device-width: 1199px;
$medium-device-width: 991px;
$small-device-width: 767px;
$extra-small-device-width: 575px;

:root {
  --header-height: 60px;
  --container-max-width: 1400px;
  --secondary-color: #{$__color-secondary};
  --secondary-color-lighten-4: #{$__color-secondary-lighten-4};
  --secondary-color-lighten-5: #{$__color-secondary-lighten-5};
  --primary-color: #{$__color-primary};
  --primary-color-lighten-4: #{$__color-primary-lighten-4};
  --primary-color-lighten-5: #{$__color-primary-lighten-5};
  --color-text: #{$__color-text};
  --color-text-secondary: #{$__color-text-secondary};
  --color-text-link: #155ca2;
}

@mixin break-xl { 
    @media (max-width: #{$extra-large-device-width}) {
      @content;
    }
  }
  
  @mixin break-lg { 
    @media (max-width: #{$large-device-width}) {
      @content;
    }
  }
  @mixin break-md {
    @media (max-width: #{$medium-device-width}) {
      @content;
    }
  }
  @mixin break-sm {
   @media (max-width: #{$small-device-width}) {
      @content;
    }
  }
  @mixin break-xs {
    @media (max-width: #{$extra-small-device-width}) {
      @content;
    }
  }
