@import "../../styles/variables";

.sidebar-menu{
    max-width: 373px;
    transition: 0.3s;
    z-index: 1000;
    position: sticky;
    top: var(--header-height);

    .menu-holder{
        background-color: rgb(253, 252, 255);
        border-radius: 10px;
        padding: 20px 20px 40px;
        width: 100%;
    }

    a {
        margin-bottom: 15px;

        &.mobileMenu {
          display: none;
          
          @media (max-width: 767px) {
            display: flex;
          }
        }
    }

    .border-white{
        padding-top: 15px;
        margin-top: 15px;
        border-top: 2px solid white;
    }


    .avatar {
        border-radius: 5px !important;
    }

    .author-name {
        color: var(--color-text);
        // font-size: 20px;
        font-weight: 700;
        text-decoration: none;
        transition: all 0.3s ease;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .user-location {
        font-size: 0.875em;
        color: var(--color-text-secondary);
        font-weight: 400;
    }

    .user-stats {
        font-size: 0.875rem;
        color: var(--color-text-secondary);
        & > div {
            display: flex;
            align-items: center;
            gap: 5px;

            .badge{
                font-size: 0.875em;
                
                &.bg-primary {
                    background-color: var(--secondary-color) !important;
                }
            }

            span {
                color: var(--color-text);
                font-weight: 700;
            }
        }
    }
}

.sidebar-black-overlay{    
    @include break-md{
        z-index: 1000;
        position: fixed;
        width: 100%;
        height: 100%;        		
        top: 0;
        left: 0;
        animation: fadein 1s;
        background-color: rgba(black, 0.5);        
    }
}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

