.ctaBack {
  padding: 20px 40px;
  @media (max-width: 575px) {
    padding: 20px;
  }
}

.custom-group-card {
  // max-width: 800px;
  // margin: 0 auto;
  // margin-top: 30px;
  .main-content {
    margin-right: 0;
  }
}

.noDataTitle{
  margin-top: 30px;
  // width: 50%;
  text-align: center;
  // float: right;
}

.noDataTitle2 {
  margin-top: 30px;
  // width: 50%;
  text-align: center;
  // float: left;

}

.card {
  width: 100%;
  margin-top: 30px;
  // border-radius: 1rem !important;

  .h5 {
    font-size: 15px !important;
  }

  .card-header-tabs {
    margin: 0 !important;
    padding: 0 !important;
    border-bottom: 1px solid #dee2e6 !important;
  }

  .nav-tabs .nav-link {
    color: black !important;
    text-align: center;
    border: 0;
    padding: 20px 40px;
    border-bottom: 3px solid transparent;
    @media (max-width: 575px) {
      padding: 15px !important;
    }
    &.active {
      border: none;
      border-bottom: 3px solid var(--primary-color);
      color: var(--primary-color) !important;
    }
  }
}

iframe {
  height: 64vh!important
}
