@import "../../styles/variables";
.loginPage {
  width: 100%;
  min-height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/loginbg.png");
  background-size: cover;
  background-position: center;
  margin-top: var(--header-height);
  @include break-md {
    padding: 10px;
    padding-bottom: 111px;
  }
  .loginBox {
    background-color: white;
    padding: 60px;
    width: 450px;
    max-width: 100%;
    @include break-md {
      padding: 20px;
    }
  }

  .fb-btn {
    background-color: #3b5998 !important;
  }

  .or-btn {
    display: block;
    width: 100%;
    margin: 5px 0;
    text-align: center;
  }

  .btn.inverted {
    @include break-md {
      padding: 0;
      border: none;
      background: transparent;
      color: var(--primary-color);
      line-height: 1;
      box-shadow: none;
    }
  }
}

.register-actions {
  .icon-link {
    flex: 1;
    span {
      text-align: center;
      display: block;
      width: 100%;
    }
  }
}

.termText {
  font-size: 12px;
  padding-left: 25px;
  .link.icon-link {
    display: inline-block;
    span {
      text-transform: capitalize;
    }
  }
  span {
    display: inline-block;
  }
}

.custom-modalbody {
  padding: 0 !important;
  & iframe {
      height: 80vh;
  } 
}

.tc {
  font-size: 10px!important;
}
.error-msg {
  color: red;
  text-align: center;
  margin-top: 10px;
}
