
@import "../../../styles/variables";
  

.dropdown-search-wrapper{
    position: absolute;
    border: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 0 6px rgba($__color-text, 0.3);
    z-index: 1000;
    background-color: white;

   
    overflow: scroll;

    .ps{
      max-height: 200px;
    }
    ul{
      padding: 0px;
      margin: 0;
      list-style: none;

      li{
        padding: 10px 10px;
        cursor: pointer;
        &:hover{
          background-color: var(--primary-color-lighten-5);
        }
      }
    }
}
  
  