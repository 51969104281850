.card .nav-tabs {
  .nav-link {
    @media (max-width: 991px) {
      flex: 0 0 33.33%;
      width: 100%;
      max-width: 33.33%;
      font-size: 14px;
    }
    &.groupInvite {
      @media (max-width: 991px) {
        flex: 0 0 25%;
        width: 100%;
        max-width: 25%;
      }
    }
  }
  &.custom-width {
    .nav-link {
      @media (max-width: 991px) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}

.share-link-container {
  max-width: 800px;
  padding: 20px 40px;
  margin: 0 auto;
  @media (max-width: 767px) {
    padding: 20px;
  }
}

.invite-modal {
  .modal-header {
    border: none;
  }
  .modal-body {
    padding: 10px;
    color: grey;

    .cmargin {
      padding-top: 20px;
    }
  }
  .modal-footer {
    border: none;
  }
  &.response {
    z-index: 99999;
    .modal-content {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    }
  }
}
.invite-users-cont {
  padding: 20px 40px;
  @media (max-width: 575px) {
    padding: 20px;
  }
}

.code-users-cont{
  padding: 20px 40px;
  @media (max-width: 575px) {
    padding: 20px;
  }
}
