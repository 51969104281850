.loader {

 height: 100%;
 width: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
 /* margin-top: 15px; */
 position: absolute;
 top: 0;
 left: 0;
}