@import "../../styles/variables";


.join-private, 
.create-group{
    padding: 20px 40px;
    background: white;
    // max-width: 800px;
    @media (max-width: 575px) {
        padding: 20px;
    }

    .private-group-form,
    .create-group-box{
        display: flex;
        align-items: flex-end;

        @include break-md{
            flex-direction: column;
        }

        .form-group{
            flex-grow: 1;
            margin-right: 25px;
            margin-bottom: 0;
            @include break-md{
                margin-right: 0px;
                margin-bottom: 25px;
                width: 100%;
            }
        }
        @include break-md{
            button{
                width: 100%;
            }
        }
    }
}

.custom-group-card {
    .card-body {
        padding: 0;
    }
}

.create-group{
    margin-bottom: 25px;
}
.main-content-narrow{
    width: 100% !important;
    p{
        text-align: center;
    }
    .qrcode-container {
        text-align: center;
    }
}
.join-public{
   
    >h2, >p{
        padding: 0 40px;
        @media (max-width: 575px) {
            padding: 20px;
        }
    }
}
.slider-wrapper {
    padding-bottom: 0 !important;
}

@include break-sm {
    .card .tab-content .single-group {
        padding: 20px 0;
    }
}

.single-group {
    padding: 20px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $__color-border;

    @media (max-width: 575px) {
        padding: 20px;
    }
    &:not(:last-child){
        border-bottom: 1px solid $__color-border;
    }
    > div {
        @media (max-width: 767px) {
            display: flex;
            align-items: center;
        }
        img{
            width: 60px;
            height: 60px;
            object-fit: cover;
            border-radius: 5px;
            margin-right: 20px;
            border: 1px solid #707271;
            @media (max-width: 767px) {
                float: left;
                flex: 0 0 60px;
            }
        }
        span {
            @media (max-width: 767px) {
                display: block;
                overflow: hidden;
            }
        }
    }


    .single-group-header{
        display: flex;
        align-items: center;
        overflow: hidden;
        
        img{
            flex: none;
        }
    }

    .single-group-details{
        font-size: 12px;
        display: flex;
        align-items: center;
        text-align: right;
        i{
            margin-left: 3px;
        }
    }

    .single-group-actions{
        display: flex;
        align-items: center;

            .dropdown{
            margin-right: 15px;
            margin-left: 10px;
        }
      
    }
    .single-group-buttons{
        display: flex;
        flex-direction: column;
        text-wrap: nowrap;
        @media (max-width: 767px) {
            padding-top: 15px;
            display: block;
        }
        .info{
            margin-top: 10px;
        }
        .infoText{
            font-size: 10px;
        }
    }
}


.whiteBackground {
    background-color: white;
}
.title {
    font-size: 20px;
    padding-left: 20px; 
    padding-top: 25px;
}
.view-group{
    .created{
        font-size: 10px; 
    }   

    .group-details-header{
        display: flex;
        align-items: center;
    
        .header{
            line-height: 1.3;
        }
        img{
            border-radius: 50%;
            width: 60px;
            height: 60px;
            object-fit: cover;
            margin-right: 20px;
            box-shadow: 0 0 10px rgba(black, 0.6);
        }
    
        .name{
            font-size: 20px;
            color: var(--primary-color);
            display: block;            
        }
    
        .address{
            font-size: 12px;  
            color: var(--primary-color);
            display: block;          
        }   
    }

    .group-admins{
        margin-top: 10px;
        .member{
            margin-bottom: 15px;
            margin-top: 0;
            font-size: 10px;
        }

        .ps{
            padding: 10px;
        }

        ul{
            margin: 0;
            padding: 0;
            list-style: inside;
            overflow: hidden;
          }
    }

    .about {
        color: var(--primary-color);
    }
     
    .contact{
        .info{
            line-height: 1.3;
            margin-bottom: 10px;
            span{
                display: block;
            } 
        }
        .infoText {
            color: var(--primary-color);
        }
    }

    .docs-wrapper{
        .doc{
            span{
                display: flex;
                align-items: center;
            }
            .greenIcon{
                color: var(--primary-color)
            }
            a{
                display: inline-flex;
                &:hover{
                    text-decoration: none;
                    color: var(--secondary-color)
                }
            }
        }
    }

    .border-bottom{
        padding-top: 20px;
        border-color: $__color-border;
        margin: 0 -50px;
        width: calc(100px + 100%)
    } 

    .settings{        
        display: flex;
        flex-direction: column; 
        justify-Content:'space-between';

        .item{
            margin-bottom: 5px;
        }
    }
}

.invite-group{
    .inviteContainer{
        display: flex;
        flex-direction: row; 
        margin-top: 20px;
    }
    .form{
        flex: 3 1 0;
        input{
            background-color:transparent;
        }
        .border-bottom{
            padding-top: 20px;
            border-color: $__color-border;
            margin:  0 -50px;
            width: calc(100px + 100%)
        }
    }
    .copy{
        // flex: 1 1 0;
        margin-top: -5px;
    }
}

.rc-slider{
    .rc-slider-handle{
        width: 20px;
        height: 20px;
        margin-top: -8px;
    }
    
    .rc-slider-track,
    .rc-slider-handle{
        background-color: var(--primary-color);
    }
    .rc-slider-handle,
    .rc-slider-handle:hover,
    .rc-slider-handle:focus{
        border-color: var(--primary-color);
        box-shadow: 0 3px 6px rgba(black, 0.4) !important;
    }
    .rc-slider-rail{
        background-color: var(--primary-color-lighten-5);
    }
    
}

.groupPortalIconBtnHolder{
    padding-bottom: 20px
}

.memberActionBtnsHolder{
    display: flex;
    flex-direction:row;

    @media (max-width: 767px) {
        flex-direction:column;
      }
}
a {
    cursor: pointer;
}


.box {
&.modal .modal-content {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}
}

.icon-row {
display: flex;
}

.custom-edit {
  margin-left: 10px;
}

.modal-content {
 &.group-list-popup {
    padding: 0;
    max-height: 600px;
    z-index: 999999;
    .modal-body {
        padding: 0;
        .close-modal {
            position: absolute;
            top: 15px;
            right: 0px;
            color: #fff;
            &.blue {
                color: var(--primary-color);
                top: 5px;
                right: -10px;
            }
        }
        .ceatepostheading {
            background-color: var(--primary-color);
            padding: 15px 30px;
            color: white;

        }
        .iconleftspan {
            margin-right: 10px;
        }
        .scroll {
            overflow: auto;
            max-height: calc(600px - 150px);
        }
    }
 }

}  

.group-body-padding{
    padding: 10px 20px 20px 20px !important;
}

body.embedded {
    .container {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
    }
    .site-footer {
        display: none;
    }
}