@import "../../styles/variables";

.main-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // padding-top: 65px;
    top: var(--header-height);
    position: relative;

    .layout-content {
        max-width: var(--container-max-width);
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .layout-sidebar,
        .layout-aside {
            display: flex;
            flex-direction: column;
            position: sticky;
            height: calc(100vh - var(--header-height));
            top: var(--header-height);
            min-width: 220px;
            width: 265px;
            z-index: 999;
            padding-top: 10px;

            // @include break-lg {
            //     width: auto;
            // }

            @include break-md {
                display: none;
            }
        }

        .layout-aside {
            justify-content: flex-end;
        }
    }
}

.notificationToaster {
    display: flex;
    justify-content: center;
    cursor: pointer;
    .close-btn {
        top: 50%;
        border: 0;
        outline: 0;
        right: 5px;
        position: absolute;
        background-color: transparent;
    }
    .toast {
        border: 0;
        padding: 10px;
        flex:  0 0 100%;
        width: 100%;
        max-width: 500px;
        background-color: #ffffff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        z-index: 99999999;
        .toast-header {
            padding: 0;
            border: 0;
            position: absolute;
            top: calc(50% - 15px);
            right: 10px;
            z-index: 9;
            .close {
                cursor: pointer;
                margin: 0 !important;
            }
        }
        .toast-body {
            padding: 5px 15px;
            position: relative;
            &:before {
                width: 4px;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                display: block;
                content: '';
                border-radius: 5px;
                background-color: var(--primary-color);
            }
            h6 {
                margin: 0 0 5px;
                font-size: 14px;
                font-weight: 600;
            }
            p {
                margin: 0;
                font-size: 10px;
            }
        }
    }
}


