@import "../../../styles/variables";

.customCheckbox{
    cursor: pointer;
    -webkit-touch-callout: none; 
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
    font-size: 12px;
    input[type="checkbox"] {
        display: none;
    }
    input[type="checkbox"] + span {
        display: inline-block;
        position: relative;
        top: -1px;
        width: 16px;
        height: 16px;
        margin: -1px 0px 0 0;
        vertical-align: middle;
        background: white left top no-repeat;
        border: 2px solid var(--primary-color);
        border-radius: 2px;
        
    }
    input[type="checkbox"]:checked + span {
        background: var(--primary-color) -19px top no-repeat;
        position: relative;
        &:after{
            font-family: "Material Icons";
            content: "done";
            color: white;
            font-size: 15px;
            position: absolute;
            left: -1px;
            top: -1px;
            line-height: 1;
        }
    }
    
    input[type="checkbox"] + span {
        margin-right: 10px;
    }
}

.tc {
    font-size: 10px!important;
}
