@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons+Outlined");
@import "./styles/helpers";
@import "./styles/variables";
@import "./styles/general";

.tab-content {
  li {
    button {
      // padding: 15px !important;
      height: 100%;
      width: 100%;
      max-width: 100% !important;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }
}
