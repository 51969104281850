@import "../../../styles/variables";

.modal-content,
.editgroupcompo {
  max-width: 800px;
  margin: 0 auto;
  // margin-bottom: 50px;
  // margin-top: 40px;
  // padding: 0;
  .close-modal {
    margin-left: auto;
  }
  .search {
    padding: 20px 30px 0;
    input {
      height: 42px;
    }
    .loading {
      text-align: center;
    }
  
  }
  .view-group {
    padding: 15px 0;
    // max-height: 60vh;
    overflow: auto;
    width: 100%;
    max-height: 400px;
    @media (max-width: 991px) {
      padding: 15px 30px;
    }
  }
  h3 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;

    > div {
      flex: 1 1 auto;
    }
    button {
      flex: 1;
    }
    button.flex-none {
      flex: 0 0 auto;
    }
  }

  .row-form-control {
    display: flex;
    align-items: center;

    .avatar-box {
      width: 100px;
      height: 100px;
      margin-right: 20px;
      background-color: #f7f7f7;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .upload-list {
      border-bottom: none;
      .material-icons {
        margin: 0;
        font-size: 40px;
      }
      .btn-icon {
        .material-icons {
          font-size: 20px;
        }
      }
    }

    .single-media {
      padding: 10px;
      img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
      }
    }
  }

  .group-admins {
    background-color: $__color-body;

    .customCheckbox {
      margin-bottom: 15px;
      margin-top: 0;
    }

    .ps {
      padding: 10px;
    }
  }
}

.upload-list {
  border-bottom: 1px solid $__color-border;
}

.mheight {
  min-height: 200px !important;
  padding: 0 !important;
}

.video-recorder__Wrapper-sc-7k20rv-0 {
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   // top: 50%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   z-index: 99999;

  &.fwDzZx {
    position: none;
  }
}

.msg-loader {
  position: absolute;
  right: 30px;
}

.capture-btn {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
}

.gif-img {
  padding: 0px 30px;
  img {
    width: 100%;
  }
}

.custom-modal {
  &.videomodel {
    .modal-content {
      min-height: 300px;

      .media-recorder-error {
        margin: 30px 10px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    video {
      width: 100%;
    }
  }

  &.error-modal {
    // margin-left: 30px;
    margin-top: 10px;
    .modal-content {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
        padding: 15px 30px!important;
      }
    }
  

  &.image-preview {
    .modal-content {
      padding: 15px!important;
    }
  }
}
