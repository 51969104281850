@import "../../styles/variables";

.my-settings-box{
    background-color: white;
    .my-settings-content{
        padding: 20px 40px;
        @media (max-width: 575px) {
            padding: 20px;
        }
    }

    h2{
        padding-bottom: 25px;
    }

    p{
        margin-bottom: 0;
    }

    .slider-wrapper{
        padding-left: 0;
        @include break-md{
            padding: 30px;
        }
    }

    .settings-actions{
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button[type="submit"]{
            margin-left: 50px;
            min-width: 180px;
        }
    }

}