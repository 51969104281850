@import "../../../styles/variables";

.modal {
    overflow: auto !important;
    scrollbar-width: 2px;
    &::-webkit-scrollbar {
        width: 2px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
    }
    
}
.modal-content{


    .close-modal{
        margin-left: auto;
    }

    .dynamic-search{
        background-color: #F3F3F3;
    }


    .actions{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
         >div{
            flex: 1 1 auto;
        }
        button{
            flex: 1;
        }

        .btnLoader {
            text-align: center;
            background-color: var(--primary-color);
            padding: 10px;
            border-radius: 16px;
            svg {
                path {
                    stroke-width: 4px;
                }
            }
        }
    }

    .newPostForm{
        .border-bottom{
            padding-top: 20px;
            border-color: $__color-border;
            margin:  0 -50px;
            width: calc(100px + 100%)
        }

        .customCheckbox{
            display: block;
            margin: 15px 0;
        }
    }
}

.upload-list{
    border-bottom: 1px solid $__color-border;
    
}

.accordion{
    .accordionSummary{
        padding-left: 0px;
        padding-right: 0px !important;
        i{
            padding-right: 0px !important;
        }
        
    }
    .accordionDetails{
        // padding: 0px !important;
    }
    .accordianSetting{
        color: var(--primary-color);
        font-size: 16px;
    }
    &::before{
        height: 0px !important;
    }
    border-bottom: 1px solid rgb(241, 234, 234) !important;
}

.call_action_button{
    // background-color:#F3F3F3 ; 
    padding: 10px;
}
.callToActionWrapper {
    display: flex;
    &__item {
        flex: 1;
        padding-right: 10px;
        button {
            width: 100%;
            text-align: center;
            justify-content: center;
        }
    }
}
.modal-content {
    .modalHeader {
        margin-bottom: 20px;
        h3 {
            font-size: 24px;
            color: var(--primary-color);
            margin-top: 0;
            margin-bottom: 8px;
        }
        p {
            font-size: 16px;
            color: var(--primary-color);
            margin: 0;
        }
    }
}