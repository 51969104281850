@import "../../styles/variables";

.notifications{
    i{
    }
    .cta {
        padding: 20px 40px;
        @media (max-width: 575px) {
            padding: 20px;
        }
    }
}

.deleteAll{
    
}

.group-link {
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
}