@import "../../../styles/variables";

.grpInfoPage {
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;

  .grpInfoTab {
    background-color: white;
    .tab-innerr {
      justify-content: space-between;
      box-shadow: 0 0 15px 0 rgba(85, 84, 84, 0.2);
      .tabss {
        width: 33%;
        border: none;
        height: 50px;
        color: black;
      }
      .active {
        border: none;
        border-bottom: 3px solid var(--primary-color);
        color: var(--primary-color) !important;
      }
    }
  }
}
.avatar-box {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  display: flex;
  overflow: hidden;
  background-color: #f7f7f7;
  align-items: center;
  justify-content: center;
  i {
    font-size: 40px;
  }
  .form-group {
    margin-bottom: 0;
    .upload-list {
      border-bottom: 0;
      .upload-list-media {
        .single-media {
          width: 70px;
          height: 70px;
          img {
            height: 100%;
            width: 100%;
          }
          .btn {
            .iconleftspan {
              i {
                font-size: 25px;
              }
            }
          }
        }
      }
    }
  }
}
.grpInfoHeadTites {
  flex:1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    margin: 0;
    margin-left: 20px;
  }
  h2 {
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @include break-xs {
      max-width: 150px;
    }
  }
}
.grpInfoHeadInput {
  flex: 1;
  padding: 0 10px;
  input {
    padding-left: 15px;
    height: 40px;
    //     width: 200px;
    max-width: 300px;
    border-radius: 5px;
    border: 1px solid rgb(71, 70, 70);
    // margin-left: 20px;
  }
}

.editTitleGrpBtns {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  height: 60px;

  justify-content: space-between;
  // align-items: center;
}

.editInfoGrp {
  margin-left: auto;
}
