@import "../../../styles/variables";


.report-actions{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.modal-header {
background: #ffffff;
height: 40px;
line-height: 40px;
padding: 5px 10px;
text-align: right;
margin-bottom: 10px;
}
.share-button{
  margin: 5px;
}

.copy {
  cursor: pointer;
}
.linkdlin {
  cursor: pointer;
  color: #007fb1;
  font-size: 30px;
  height: 30px;
}
.share-post-modal{
  // padding:0px;

  .modal-body{
    padding:0px;
  }
}

