@import "../../../styles/variables";

.message-bubble-wrapper {
  scroll-behavior: smooth;
  display: flex;
  .message-bubble {
    padding: 15px;
    display: inline-block;
    margin: 10px 0;
    background-color: white;
    border-radius: 20px;
    max-width: 70%;

    .firstName {
      font-size: 15px;
      margin: 0;
    }

    &.message-loader-div {
      margin-right: 30px !important;
    }

    .file-msg-cont,
    .reply-msg-cont {
      background-color: #d9f2e4;
      // padding: 10px;
      display: flex;
      justify-content: space-between;
      padding: 5px;
      border-left: 5px solid green;
      border-radius: 5px;
      .data-cont {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .thumbnail {
          img {
            height: 40px;
            width: 40px;
            object-fit: cover;
          }
        }
      }
      .textCont {
        .filesize {
          float: right;
          font-size: 12px;
          float: right;
          color: var(--primary-color);
          font-weight: bold;
        }
        .sendername {
          color: "#008000";
          font-weight: bold;
          font-size: 12px;
        }

        .filename {
          max-width: 100px;
          font-size: 14px;
        }

        .iconspan {
          display: inline-block;
          width: 100%;
        }
        .msg {
          font-size: 12px;
          word-break: break-word !important;
        }
      }
    }

    .message-bubble-date {
      display: block;
      // margin-top: 20px;
      font-size: 10px;
      float: right;
    }

    .send-img-cont {
      // width: 100%;
      // width: 240px;
      // position: relative;
      // // border: 1px solid var(--primary-color);
      // border-radius: 5px;
      max-width: 320px;
      // min-width: 240px;
      &.one {
        object-fit: cover;
        // width: 120px;
        // height: 120px;
        cursor: pointer;
        div {
          height: 100%;
        }
        .imgcont {
          max-height: 460px;
          overflow: hidden;
        }
        img {
          // max-height: 240px;
          // max-width: 240px;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
        &.c {
          min-width: 120px !important;
          // height: 100%!important;
          height: auto;
        }
        &.d {
          min-width: 120px !important;
          // height: 100%!important;
          height: auto;
        }
        &.a {
          min-width: 120px !important;
          // height: 100%!important;
          height: auto;
        }
      }
      &.two {
        .row {
          > div {
            flex: 0 0 50% !important;
            max-width: 50% !important;
            width: 100% !important;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              aspect-ratio: 1 / 1;
              // width: 100%;
              // height: 100%;
            }
          }
        }
      }
      &.three {
        .row {
          > div {
            flex: 0 0 50% !important;
            max-width: 50% !important;
            width: 100% !important;
            img {
              height: 170px;
              width: 100%;
              object-fit: cover;
            }
            &:last-child {
              flex: 0 0 100% !important;
              max-width: 100% !important;
              width: 100% !important;

              img {
                height: 170px;
                width: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
      &.four,
      &.more {
        .row {
          > div {
            flex: 0 0 50% !important;
            max-width: 50% !important;
            width: 100% !important;
            img {
              width: 100%;
              height: 170px;
              aspect-ratio: 1 / 1;
              object-fit: cover;
            }
          }
        }
      }
      .material-icons {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        text-align: center;
        color: #fff;
        margin-top: -7px;
        cursor: pointer;
      }
      .row {
        .imgcont {
          // &.single-image {
          //   width: 240px;
          //   height: 240px;
          // }
          img {
            // width: 240px;
            // height: 240px;
            // max-width: 240px;
            // max-height: 240px;
            // // width: 240px;
            // // height: 240px;
            // min-height: 120px;
            // height: 100%;
            // object-fit: cover;
            border: 1px solid #707271;
            cursor: pointer;
            // width: 100%;
            // cursor: pointer;
          }
        }

        .videocont {
          video {
            height: 115px;
            width: 100%;
            border: 1px solid var(--primary-color);
          }
        }
      }
      .more-media-content {
        background: rgba(0, 0, 0, 0.5);

        position: absolute;
        right: 35px;
        bottom: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-size: 32px;
          color: white;
        }
        @media (max-width: 360px) {
          right: 20px;
        }
      }
    }

    .audio-cont {
      audio {
        // border-radius: 10px;
        // background: blue;
        // width: 100%;
        cursor: pointer;

        @-moz-document url-prefix() {
          @media (max-width: 991px) {
            width: 100%;
          }
        }
      }
    }
  }

  .forward {
    .reverseicon {
      display: flex;
      flex-wrap: wrap;
      color: grey;
      font-size: 14px;
    }
    i {
      color: grey !important;
      font-size: 20px !important;
    }
  }

  &.owned {
    justify-content: flex-end;
    .message-bubble {
      background-color: $__color-bubble;
    }
  }
}
.menuoption {
  justify-content: flex-end;
  display: flex;
  margin-top: 25px;
  margin-right: 10px;
}

.dropMenus{
max-height: 140px;
overflow: auto;
min-width: 11rem;

}
/* width */
.dropMenus::-webkit-scrollbar {
  width: 5px;
  border-radius: 2px;
}

/* Track */
.dropMenus::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.deleteForMeAndALL{
  height: 400px;
}

/* Handle */
.dropMenus::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.dropMenus::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.single-post-media {
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-color: #dadada;
  img,
  video {
    width: 100%;
    object-fit: cover;
  }

  button.muted {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 10;
  }
  .play {
    position: absolute;
    right: 0px;
    left: 0px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      color: var(--primary-color);
      font-size: 25px;
      border-radius: 50%;
      padding: 15px;
      background-color: white;
    }
  }

  .more-media-content {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 32px;
      color: white;
    }
  }
}

.delete-options {
  color: var(--primary-color);
  font-weight: bold;
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 14px !important;
  cursor: pointer;
}

.spinner-border {
  color: var(--primary-color) !important;
}

.multipleDownload {
  margin-bottom: 10px !important;
}

.forPadding{
  padding: 20px 35px !important;
  margin-left: 10px;


  @media only screen and  (max-width: 550px){
    .forButtons{
      button{
        line-height: 10px;
        margin-left: 10px;
      }
    }

  }
  
}
