@import "../../../styles/variables";

.mentions-input {
  font-size: 16px;
  color: var(--primary-color);
  width: 100%;
  border: none;
  border-radius: 0;
  line-height: 1.1;
  min-height: calc(1.5em + .75rem + calc(var(--bs-border-width)* 2));
  padding: 5px 0;
  height: auto;
  resize: none;
}

.mentions-input__input {
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  padding: 6px 1px;
  margin:0;
  border:0;
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}

.mentions-input__input:focus {
  border-bottom: 2px solid var(--secondary-color);
}

.mentions-input__suggestions {
  left: 0;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
}



.mentions {
  background-color: #daf4fa;
}

.suggestion-item {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 6px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.suggestion-item.focused {
  background-color: #cee4e5;
}

.avatar {
  width: 35px;
  height: 35px;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
}

.suggestion-info {
  display: flex;
  flex-direction: column;
}

.suggestion-name {
  font-weight: bold;
}

.suggestion-type {
  font-size: 12px;
  color: #888;
}
