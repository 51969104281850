// .analyseModal {
//   z-index: 1051;
// }

.no-padding {
  padding: 5px !important;
}
.icon-cont {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: var(--primary-color);
  .icon-div {
    cursor: pointer;
    color: #fff;
    border-bottom: 5px solid transparent;
    padding: 5px 10px;
    flex: 0 0 25%;
    max-width: 25%;
    width: 100%;
    text-align: center;
    opacity: 0.5;
    
    &.active {
      border-bottom: 5px solid #fff;
      color: #fff !important;
      opacity: 1;
    }
    &.iconleftspannew {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .icon-btn-clear {
      padding: 0;
      border: none;
      background: transparent;
      color: var(--primary-color);
      display: flex;
      align-items: center;
      box-shadow: none;
      & i {
        font-size: 25px !important;
      }
      &.active {
        color: white !important;
        width: 100%;
        display: block;
      }
      &.inactive {
        color: #fff !important;
        display: block;
        width: 100%;
        opacity: 0.5;
      }
    }
    &.inactive {
      color: #fff !important;
      display: block;
      width: 100%;
      opacity: 0.5;

    }
  }

  .count {
    // color: grey !important;
    padding-left: 0;
  }
}
.tabData {
  .comment-section {
    .data-section {
      display: flex;
      justify-content: space-between;
      border-bottom: 3px solid #eee;
      padding-bottom: 10px;
      padding-top: 10px;
      .left {
        display: flex;
        .img-cont {
          img {
            border-radius: 5px;
            width: 50px;
            height: 50px;
            border: 1px solid #707271;
          }
        }
        .text-data {
          padding-left: 10px;
          max-width: 250px;
          .subheader {
            display: block;
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
    }
  }
  .analysetab {
    .btn-cont {
      display: flex;
      justify-content: center !important;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
