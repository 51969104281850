@import "../../../../styles/variables";

.mainTabCont {
  max-height: calc(100vh - 330px);
  padding: 20px 40px;
  overflow: auto;
  @include break-sm {
    padding: 10px;
    max-height: calc(100vh - 275px);
  }
  .mediaInside {
    display: flex;
    flex-direction: column;
    .monthWise {
      p {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .images {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        .imgg{
          flex: 0 0 25%;
          max-width: 25% ;
          padding-right: 2px;
          margin-bottom: 2px;

          img {
            width: 100%;
            height: 150px;
            cursor: pointer;
            object-fit: contain;
            border: 1px solid #707271;
          }
        }
        
      }
    }
  }
  .docInside {
    .monthBox {
      p {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .docContainer {
        margin-bottom: 10px;
        .docBox {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          padding: 4px 10px;
          margin-bottom: 10px;
          border: 1px solid rgb(180, 175, 175);
          border-radius: 10px;
          .details {
            display: flex;
            flex-direction: column;
            .title{
              font-size: 17px;
              padding: 0;
            }
            .detailsBottom {
              font-size: 13px;
            }
          }
          
        }
      }
    }
  }

  .linkInside {
    .linkBox {
      p {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .linkContainer {
        .mainLink {
          border: 1px solid rgb(180, 175, 175);
          margin-bottom: 10px;
          border-radius: 4px !important;
          box-shadow: 1px 1px 1px 1px #f1ecec;
          .upper {
            display: flex;
            border-bottom: 1px solid rgb(180, 175, 175);

            img {
              width: 80px;
              height: 90px;
              border-radius: 10px 0px 0px 0px;
            }
            .upperRight {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: 0px 10px;
              .text{
                word-break: break-word;
              }
              .des {
                font-size: 12px;
                color: rgb(139, 135, 135);
                word-break: break-word;

              }
              
            }
          }
          .lower {
            padding: 10px !important;
            .msg{
              font-size: 17px;
              a{
                word-break: break-all !important;
              }
            }
            span {
              cursor: pointer;
              word-break: break-word;
            }
          }
        }
      }
    }
  }
}

.noData{
  text-align: center;
}
