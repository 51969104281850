@import "../../styles/variables";


.gdpr{
   
    h2{
        padding: 25px 20px 20px 20px;
        border-bottom: 1px solid $__color-border;
    }
    .html-content{
        padding: 20px 0;
        line-height: 1.6;
        h4{
            font-size: 20px;
            margin-top: 20px;
            margin-bottom: 15px;
        }
        p{
            line-height: 1.8;
        }
    }
}


