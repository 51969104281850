@import "../../../styles/variables";

#locci-lightbox {
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // justify-content: space-between;
  // padding: 15px;
  // text-align: center;
  // overflow: auto;

  // @media (max-width: 767px) {
  //   padding: 30px;
  // }

  .nameTime{
    width: 100%;
    text-align: center;
    color: white;

    .name{
      margin: 0;
      font-size: 20px;
    }
    .time{
      margin: 0;
      margin-bottom: 10px;

    }
  }

  .imgview {
    width: 100%;
    padding: 15px;
    text-align: center;
    max-width: 1024px;
    margin: 0 auto;
  }

  .lightbox-item {
    max-width: 100%;
    object-fit: contain;
    max-height: calc(100vh - 120px);
  }
  .btn {
    i {
      font-size: 24px;
      color: white;
      margin-right: 0;
    }
    &:hover {
      opacity: 0.8;
    }
  }

  .leftnav {
    position: absolute;
    top: 50%;
    left: 15px;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    // @media (max-width: 767px) {
    //   width: 100%;
    //   position:unset;
    // }
  }

  .rightnav {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 40px;
    height: 40px;
    margin-top: -20px;
  }

  .close-lightbox {
    position: absolute;
    top: 20px;
    right: 20px;
    @media (max-width: 767px) {
    }
  }
}
