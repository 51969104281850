@import "../../../styles/variables";


.report-actions{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.confirm_post_modal {
    box-shadow: 0 0px 5px 5px #888888;
}
