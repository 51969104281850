.main-Div-moreLess {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .readMoreLess {
    font-weight: 600;
    color: rgb(0, 57, 92) !important;
  }
}

.closeButtonChat {
  display: flex;
  margin-left: auto;
  margin-bottom: 10px;
}

.chat-confirmation-modal{
  width: 270px;
  padding: 5px !important;
  // box-shadow: 5px 10px #888888;
  // height: 200px;
}

.modalBodys{
  padding: 25px 25px !important;
}

.report-actionss{
  :first-child{
    margin-bottom: 10px;
  }
  display: flex;
  flex-direction: column;
}

.lowerCaseBtn{
  button{
    text-transform: none;

  }
}

.twitterspan{
  &--link{
    word-break: break-all;
  }
}