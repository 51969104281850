
@import "../../../styles/variables";
  

.dynamic-search{
  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    height: 150px;
    overflow: hidden;
  }
}

  
  