@import "../../styles/variables";
.news-container {
  top: 226px;
  // left: 0;
  background-color: white;
  position: fixed;
  z-index: 999;
  width: inherit;
  max-width: inherit;
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    // max-width: 400px;;
    left: 0px;
    right: 0px;
  }

  

  .first-row {
    display: flex;
    padding: 20px 40px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 575px) {
      padding: 20px;
    }
    .data-cont {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .imgcont {
        img {
          width: 60px;
          height: 60px;
          border-radius: 5px;
          border: 1px solid #707271;
        }
      }
      .textCont {
        margin-left: 20px;
        .heading {
          font-weight: bold;
          font-size: 16px;
          color: var(--primary-color);
        }
        .date {
          font-size: 10px;
          color: #484848;
          display: block;
        }
      }
    }

    .right-side {
      .crossCont {
        position: absolute;
        top: 10px;
        right: 10px;
        i {
          float: right;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }
      .btnCont {
        width: 100%;

        .newstoggle {
          color: var(--primary-color);
          background: white;
          box-shadow: 0 2px 10px rgba(black, 0.3) !important;
          &:focus {
            box-shadow: 0 2px 10px rgba(black, 0.3) !important;
          }
        }

        .custom-btn {
          padding: 10px 20px;
        }
      }
    }
  }
  .second-row {
    .shownews-default {
      display: none;
      max-height: 0px;
      // transition: opacity 500ms;
      overflow: hidden;
      padding: 20px 40px;
      border-top: 3px solid var(--primary-color);
      @media (max-width: 575px) {
        padding: 20px;
      }
      h5 {
        margin-bottom: 0;
      }
    }

    .shownews-default.shownews {
      max-height: 200px;
      overflow: auto;
      display: block;
      transition: opacity 500ms;
      margin-bottom: 10px!important;
    }
  }

  // &.ingroup {
  //   position: relative!important;
  //   top:0!important
  // }
}
.onlyHome{
  top: 120px !important;
}

// .ingroup {
//   position: relative!important;
// }

.nomarginb {
  margin-bottom: 0!important;
}
