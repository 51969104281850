body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body,
input,
textarea,
label,
h1,
h2,
h3,
h4 {
  font-family: "Roboto", sans-serif;
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

a {
  color: var(--primary-color);

  &:hover {
    text-decoration: none;
  }
}

.white {
  color: white;
}

.green {
  color: var(--primary-color);
}

.purple {
  color: var(--secondary-color);
}

.black {
  color: black;
}

.clickable {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.rotate90 {
  transform: rotateZ(-90deg);
}

body {
  font-size: 16px;
  background-color: $__color-body;
  // min-height: 100vh;
  color: var(--color-text);
  // padding-top: 60px;

  &.embedded {
    padding-top: 0;

    .main-container {
      padding-top: 0;
    }

    .group-profileCard.card {
      margin-top: 0 !important;
    }

    .site-navbar {
      display: none;
    }
  }
}

* {
  outline: none !important;
}

*:focus {
  box-shadow: none !important;
}

.main-content {
  // padding-top: 30px;
  padding-bottom: 60px;
  // margin-right: 200px;
  flex: 1 1 auto;
  max-width: 100%;
  // overflow: hidden;

  @include break-md {
    margin: 0;
    padding-bottom: 91px;
  }
  @media (max-width: 767px) {
    padding-bottom: 0;
    margin-bottom: 10px;
  }
  &.minHeight {
    @include break-md {
      min-height: 50px;
    }
  }

  .main-content-narrow {
    margin: 0 auto;
    max-width: 630px;
    padding: 0 15px;
  }
}

.schlumpf-content-narrow {
  width: 100% !important;
  p {
    text-align: center;
    margin: 20px;
  }
}

.chat-window {
  // margin-right: 200px;
  flex: 1 1 auto;
  // width: 100%;

  @media (max-width: 1024px) {
    margin-right: 0;
  }

  .sending-overlay {
    z-index: 999991;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    animation: fadein 1s;
    background-color: rgba(black, 0.5);
  }

  @include break-md {
    margin: 0;
    width: 100%;
  }
  .chat-window-narrow {
    max-width: 600px;
    margin: 0 auto;
    @include break-md {
      padding-bottom: 91px;
    }
    @media (max-width: 767px) {
      padding-bottom: 0;
    }

    // @media (max-width: 575px) {
    //   height: 100vh;
    // }
  }
}

.dropdown-menu {
  box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.2) !important;
  padding: 15px 20px !important;
  border-radius: 3px !important;
  border: none !important;

  :last-child {
    // margin-bottom: 0 !important;
  }

  .btn,
  a {
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
  }

  .btn-normal {
    margin-bottom: 15px;
    display: block;
  }
}
.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;

  &::after {
    content: none !important;
  }
}


label {
  margin-bottom: 0;
  font-size: 12px;
}

input.form-control,
select.form-control,
.react-datepicker-wrapper input,
textarea.form-control {
  font-size: 16px;
  color: var(--color-text);
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid var(--primary-color);
  padding: 0 10px 0px 0;
  line-height: 1.1;
  height: 2em;
  &::placeholder {
    color: var(--color-text-secondary);
    font-size: 12px;
  }
}
input.form-control:focus,
select.form-control:focus,
textarea.form-control:focus {
  color: var(--color-text);
  border-bottom: 2px solid var(--secondary-color);
}
textarea.form-control {
  padding: 5px 0;
  height: auto;
  resize: none;
}

.formMessage {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin-top: 15px;
  color: red;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.modal {
  top: 60px;
  height: calc(100% - 60px);

  &.enter-group-confirm {
    z-index: 999999;
  }

  &.group-rules-modal {
    z-index: 99999999;
  }
}

.modal-body {
  padding: 30px;
  @include break-md {
    padding: 15px;
  }
}

.modal-content {
  border-radius: 0;
  border: none;
}

.react-datepicker-wrapper {
  width: 100%;
}

.decoration-none:hover {
  &,
  div,
  span,
  a,
  p {
    text-decoration: none;
    color: var(--secondary-color);
  }
}

.slider-wrapper {
  padding: 20px 40px;
  @media (max-width: 575px) {
    padding: 20px;
  }

  &.mwidth {
    padding-left: 0;
    max-width: 800px;
  }
  .slider-labels {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin: 10px -37px;
    span {
      width: 100px;
      &:nth-child(3) {
        text-align: center;
      }
      &:nth-child(2) {
        text-align: center;
      }
      &:nth-child(1) {
        text-align: center;
      }
    }
  }
}

.avatar {
  box-shadow: 0 0 10px rgba(black, 0.6);
}

.iframe {
  height: calc(100vh - 60px);
}

aside.emoji-picker-react {
  width: 100% !important;
}
.single-post .single-post-comments .new-comment-form {
  align-items: center !important ;
}

.chat-window-narrow .new-message-form {
  align-items: center !important ;

  @media (max-width: 767px) {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.back-cont {
  margin: 0 auto;
  max-width: 800px;

  &.space {
    padding-top: 60px;
  }
}
.back {
  &.link.icon-link i {
    margin-right: 5px;
  }
}

.custom-mwidth {
  max-width: 800px;
}

.nopadding {
  padding: 0 !important;
}

.reset-maxwidth {
  max-width: 100% !important;
}

.p-40 {
  padding-top: 40px !important;
}

.tab-content {
  padding-top: 0 !important;

  .active {
    position: relative;
  }
}

// .tab-content>.active {
//   position: relative;
// }

.custom-modal {
  &.dynamiczindex {
    z-index: 1;
  }
  // z-index: 1;
  &.viewGrpModal {
    z-index: 99999;
  }
  .modal-content {
    position: relative;

    .closeIcon {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px 0 5px 5px;
      width: auto;
      height: auto;
    }

    .user-details {
      padding-top: 25px !important;
      padding-bottom: 0 !important;
      .group-contactCard {
        margin-bottom: 40px !important;
      }
    }
  }
}

.cursor {
  cursor: pointer;
}

.downloadable {
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.sending-overlay {
  z-index: 999991;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  animation: fadein 1s;
  background-color: rgba(black, 0.1);
}
.delete-confirmation-modal {
  box-shadow: 10px 0px 0px 10px #1111;
}

//Generated SVGs
.fill-current {
  fill: currentColor;
}
.container {
  padding: 0 15px;
  max-width: 830px;
  margin-bottom: 70px;
  @include break-sm {
    margin-bottom: 0px;
  }
}

.feed {
  .container {
    max-width: 630px;
  }
}
