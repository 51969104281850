@import "../../styles/variables";

.inbox {
  .read {
    gap: 15px;

    .user-card-content {
      gap: 20px;

      flex: 1 1 auto;

      .avatar {
        margin-right: 0;
      }

      span {
        color: $__color-text;
      }
    }

    .user-card-actions {
      i {
        color: $__color-text;
      }
    }
  }

  .truncate-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-card-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 0;

    span {
      font-weight: bold;
      color: $__color-text;
    }
    
    @include break-sm {
      font-size: 0.875rem;
    }
  }

  // @include break-sm {
  //   .user-card {
  //     flex-direction: column;
  //     align-items: flex-start;
      
  //     .user-card-actions {
  //       margin-left: 0;
  //       flex-direction: row;
  //       align-items: flex-end;
  //       justify-content: end;
  //       width: 100%;
  //       gap: 10px;
  
  //       & > span:first-child {
  //         flex: 1;
  //       } 
  //     }
  //   }
  // }

  .joinedOn {
    font-size: 12px !important;
    color: #030303 !important;
    font-weight: normal !important;
    max-width: 96px;
    text-align: right;
  }
}

.backCta {
  padding: 20px 40px;

  @media (max-width: 575px) {
    padding: 20px;
  }
}


.chat-window-narrow {
  padding-top: 95px;
  height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;

  @media (max-width: 767px) {}

  @include break-md {
    margin: 0;
  }

  #scrollableDiv {

    // .infinite-scroll-component__outerdiv {
    //   .infinite-scroll-component {
    //     overflow: auto;
    //     display: flex;
    //     flex-direction: column-reverse;
    //     @media screen and (-moz-windows-theme) {
    //       overflow: hidden !important;
    //     }
    //   }
    // }
    .cutomScroll {
      overflow: hidden !important;
      min-height: calc(100vh - 367px);

      @media (max-width: 991px) {
        min-height: calc(100vh - 398px);
      }
    }
  }


  // > .group-title {
  //   left: 50%;
  //   margin-left: -400px;
  // }

  .messages-wrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    // padding-top: 100px;
    white-space: pre-wrap;

    .w-100 {
      word-break: break-word;
    }
  }

  .perfect-scrollbar-custome {
    margin-top: 100px;
  }

  .new-message-form {
    display: flex;
    align-items: flex-end;
    background-color: #dae3e2;
    padding: 15px;

    input {
      background-color: transparent;
    }

    .form-group {
      flex-grow: 1;
      margin-right: 15px;
      margin-bottom: 0;

      .msg-input {
        border-radius: 15px;
        padding: 20px;
        min-height: 56px;
        max-height: 100px;
        field-sizing: content;

        &::placeholder {
          font-size: 15px;
        }
      }
    }
  }
}

.chatTag {
  font-size: 14px;
  font-weight: 400;
}

.new-message {
  .reply-msg-cont {
    background-color: lightgray;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-left: 5px solid green;

    .textCont {
      .sendername {
        color: "#008000";
        font-weight: bold;
        font-size: 12px;
      }

      .msg {
        font-size: 12px;
        word-break: break-word !important;
      }
    }
  }

  .crossCont {
    i {
      cursor: pointer;
    }
  }

  // @media (max-width: 575px) {
  //   position: fixed;
  //   bottom: 0;
  // }
}

.attachment {
  .modal-content {
    padding: 0;
  }

  .modal-body {
    margin: 15px;
    padding: 0 !important;
  }

  .item-container {
    display: flex;
    flex-direction: column;
    align-items: left;

    .item {
      // border: 2px solid slateblue;
      display: flex;
      width: 100%;
      padding: 10px 0px 10px 0px;
      // margin-bottom: 10px;
      justify-content: left;
      // border-bottom: 1px solid black;
    }

    button {
      .iconleftspan {
        font-size: 15px;

        i {
          font-size: 25px;
        }
      }
    }

    .file-upload {
      display: none;
    }
  }

  .form-group {
    margin-bottom: 0 !important;

    .file-upload-btn {
      font-weight: bold;
      font-size: 12px;
      // text-transform: uppercase;
      transition: 0.3s;
      line-height: 1.5;
      padding: 0;
      border: none;
      background: transparent;
      color: var(--primary-color);
      display: flex;
      align-items: center;
      box-shadow: none;
      cursor: pointer;

      .label-upload {
        font-size: 15px !important;
        border: none !important;

        transition: 0.3s;

        &:hover {
          color: var(--secondary-color);
        }

        .span {
          font-size: 15px !important;

          i {
            font-size: 25px !important;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.equalizer {
  position: relative;
  height: 40px;
  width: 60px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.equalizer .bar {
  height: 1px;
  width: 3px;
  display: inline-block;
  background-color: #f95e7a;
  bottom: 0;
  position: absolute;
  animation-duration: 500ms;
  -webkit-animation-duration: 500ms;
  animation-play-state: running;
  -webkit-animation-play-state: running;
  animation-direction: alternate;
  -webkit-animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

.equalizer .bar.bar-1 {
  left: -2px;
  animation-name: musicBeat1;
  -webkit-animation-name: musicBeat1;
  animation-delay: 0ms;
  -webkit-animation-delay: 0ms;
}

.equalizer .bar.bar-2 {
  left: 5px;
  -webkit-animation-name: musicBeat2;
  animation-name: musicBeat2;
  -webkit-animation-delay: 300ms;
  animation-delay: 300ms;
}

.equalizer .bar.bar-3 {
  left: 12px;
  -webkit-animation-name: musicBeat3;
  animation-name: musicBeat3;
  -webkit-animation-delay: 600ms;
  animation-delay: 600ms;
}

.equalizer .bar.bar-4 {
  left: 20px;
  -webkit-animation-name: musicBeat4;
  animation-name: musicBeat4;
  -webkit-animation-delay: 900ms;
  animation-delay: 900ms;
}

.equalizer .bar.bar-5 {
  left: 28px;
  -webkit-animation-name: musicBeat5;
  animation-name: musicBeat5;
  -webkit-animation-delay: 1200ms;
  animation-delay: 1200ms;
}

.equalizer .bar.bar-6 {
  left: 36px;
  -webkit-animation-name: musicBeat6;
  animation-name: musicBeat6;
  -webkit-animation-delay: 1500ms;
  animation-delay: 1500ms;
}

.equalizer .bar.bar-7 {
  left: 42px;
  -webkit-animation-name: musicBeat7;
  animation-name: musicBeat7;
  -webkit-animation-delay: 1800ms;
  animation-delay: 1800ms;
}

.equalizer .bar.bar-8 {
  left: 50px;
  -webkit-animation-name: musicBeat8;
  animation-name: musicBeat8;
  -webkit-animation-delay: 2100ms;
  animation-delay: 2100ms;
}

.equalizer .bar.bar-9 {
  left: 58px;
  -webkit-animation-name: musicBeat9;
  animation-name: musicBeat9;
  -webkit-animation-delay: 2400ms;
  animation-delay: 2400ms;
}

.equalizer .bar.bar-10 {
  left: 66px;
  -webkit-animation-name: musicBeat10;
  animation-name: musicBeat10;
  -webkit-animation-delay: 2700ms;
  animation-delay: 2700ms;
}

@keyframes musicBeat1 {
  from {
    height: 1px;
  }

  to {
    height: 7px;
  }
}

@-webkit-keyframes musicBeat1 {
  from {
    height: 1px;
  }

  to {
    height: 7px;
  }
}

@keyframes musicBeat2 {
  from {
    height: 1px;
  }

  to {
    height: 15px;
  }
}

@-webkit-keyframes musicBeat2 {
  from {
    height: 1px;
  }

  to {
    height: 15px;
  }
}

@keyframes musicBeat3 {
  from {
    height: 1px;
  }

  to {
    height: 33px;
  }
}

@-webkit-keyframes musicBeat3 {
  from {
    height: 1px;
  }

  to {
    height: 33px;
  }
}

@keyframes musicBeat4 {
  from {
    height: 1px;
  }

  to {
    height: 17px;
  }
}

@-webkit-keyframes musicBeat4 {
  from {
    height: 1px;
  }

  to {
    height: 17px;
  }
}

@keyframes musicBeat5 {
  from {
    height: 1px;
  }

  to {
    height: 28px;
  }
}

@-webkit-keyframes musicBeat5 {
  from {
    height: 1px;
  }

  to {
    height: 28px;
  }
}

@keyframes musicBeat6 {
  from {
    height: 1px;
  }

  to {
    height: 20px;
  }
}

@-webkit-keyframes musicBeat6 {
  from {
    height: 1px;
  }

  to {
    height: 20px;
  }
}

@keyframes musicBeat7 {
  from {
    height: 1px;
  }

  to {
    height: 17px;
  }
}

@-webkit-keyframes musicBeat7 {
  from {
    height: 1px;
  }

  to {
    height: 17px;
  }
}

@keyframes musicBeat8 {
  from {
    height: 1px;
  }

  to {
    height: 12px;
  }
}

@-webkit-keyframes musicBeat8 {
  from {
    height: 1px;
  }

  to {
    height: 32px;
  }
}

@keyframes musicBeat9 {
  from {
    height: 1px;
  }

  to {
    height: 33px;
  }
}

@-webkit-keyframes musicBeat9 {
  from {
    height: 1px;
  }

  to {
    height: 15px;
  }
}

@keyframes musicBeat10 {
  from {
    height: 1px;
  }

  to {
    height: 7px;
  }
}

@-webkit-keyframes musicBeat10 {
  from {
    height: 1px;
  }

  to {
    height: 7px;
  }
}

.pause-btn,
.play-btn {
  .iconleftspan {
    i {
      font-size: 40px !important;
    }
  }
}

.record-timer {
  font-size: 20px;
  color: #ff0000;
}

.pulse {
  animation: pulse-animation 1s infinite;
}

@keyframes pulse-animation {

  // 0% {
  //   box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  // }
  // 100% {
  //   box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  // }
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.Progressbar {
  position: relative;
  height: 5px;
  border-radius: 1000px;
  background-color: #484848;
  color: #f95e7a;
  width: 100%;
  margin: 15px 20px;
}

.Progressbar__value {
  height: 5px;
  transition: width 0.4s ease-in-out;
  border-radius: 1000px 0 0 1000px;
  background-color: #ff4c65;
  width: 1px;
}

.record-audio-tag {
  height: 0px;
  opacity: 0;
}

.last-message {
  color: #484848;
  font-size: 12px;
}

.last-message.icon-btn-clear {
  color: #484848;
  font-size: 12px;
}

#container-circles {
  cursor: pointer;
}

.stoprecorder1 {
  height: 3px !important;
}

.stoprecorder2 {
  height: 6px !important;
}

.stoprecorder3 {
  height: 12px !important;
}

.stoprecorder4 {
  height: 8px !important;
}

.stoprecorder5 {
  height: 14px !important;
}

.stoprecorder6 {
  height: 5px !important;
}

.stoprecorder6 {
  height: 5px !important;
}

.react-html5-camera-photo>img,
.react-html5-camera-photo>video {
  max-width: 100% !important;
  max-height: 100% !important;
}

.chat-window-narrow {}

.infinite-scroll-component__outerdiv {
  // .infinite-scroll-component{
  //   overflow: auto;
  //   display: flex;
  //   flex-direction: column-reverse;
  //   @media screen and (-moz-windows-theme) {
  //     overflow: hidden !important;
  //   }
  // }
}

.infinite-scroll-component__outerdiv {
  // @media screen and (-moz-windows-theme) {
  //   height: 100%;
  // }
}

#scrollableDiv {
  .infinite-scroll-component__outerdiv {
    height: auto;
  }

}

// }

.file-uploader-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 75vh;
  .drop_area {
    flex: 1;
    background-color: rgb(255, 255, 255);
    border: 2px solid #E3E3E3;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 20px;
    &:hover {
      border-color: var(--secondary-color);
    }
  }
}

.custom-message {
  width: 100%;
  text-align: center;
  .heading {
    color: #848488;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 6px;
    line-height: 30px;
    @media (min-width: 768px) {
      font-size: 32px;
      line-height: 44px;
    }
  }

  .para {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #0F395B;
    margin-bottom: 0;
    line-height: 24px;
    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 28px;
    }
  }
}

.fileUploaderModal {
  padding: 15px;
  .modal-dialog {
    max-width: 800px;
  }
  .modal-content {
    background-color: transparent;
    padding: 0;
  }
  .modal-body {
    padding: 0;
  }
}

.sendMediaModal {
  .closeIcon {
    position: inherit !important;
  }
}