@import "../../styles/variables";


.site-navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2);
  z-index: 1051;

  .navbar-container {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    position: relative;
    width: 100%;
    max-width: var(--container-max-width);
    margin-left: auto;
    margin-right: auto;
  }

  .navbar-content {
    flex:1;
    position: relative;
    display: flex;
    width: 100%;
    max-width: 600px;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;

    @media (max-width: 575px) {
      padding: 0 20px;
    }
  }

  .navbar-logo {
    display: flex;
    align-items: center;
    min-height: 60px;
  }

  .navbar-left-side,
  .navbar-right-side {
    flex: 0 0 15%;
  }

  .navbar-left-side {
    button {
      transition: 0.3s;
    }

    i {
      font-size: 24px;
      margin: 0;
      color: white !important;
    }
  }
  
  .navbar-right-side {
    display: flex;
    align-items: center;
    gap: 19px;
    justify-content: flex-end;

    .dropdown {
      .mobileMenu {
        display: none;
      }

      @include break-md {
        .mobileMenu {
          display: flex;
        }
      }
    } 

  }

  .navbar-title {
    color: white;
    font-size: 20px;
    img{
      width: 85px;
    }
  }

  #dropdown-profile {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 8px;
      // border: 2px solid #707271;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
    }
  }

  .navbar-search-input {
    background-color: transparent;
    border-color: white !important;
    padding: 0 10px;
    color: white;
    width: 100%;
    transition: all 0.3s ease 0.3s;

    &:focus {
      background-color: white;
      border-color: white !important;
      border-radius: 10px;
      &::placeholder {
        color: var(--primary-color);
      }
    }

    &::placeholder {
      color: white;
    }
  }

  .dropdown-menu {
    i {
      margin-right: 10px;
    }
  }

  .navbar-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 60px;
      li {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .locciicon {
            width: 40px;
            height: 40px;
            border-radius: 50%;

            @include break-md {
                display: none;
            }
        }
        &.greenBackground{
          a{
            i {
              color:var(--secondary-color);
            }
          }
        }
        
        a {
            text-align: center;
            display: inline-block !important;

            
            i {
              color: var(--color-text-secondary);
              
                font-size: 30px !important;
                margin-right: 0 !important;
                @media (max-width: 767px) {
                  font-size: 35px !important;
                }
                
            }

            span {
                font-size: 12px;
                // font-weight: bold;
                // text-transform: uppercase;
                // vertical-align: middle;
                display: block;
                color: var(--color-text-secondary);
                @media (max-width: 767px) {
                  display: none;
                }
    
            }
        }
        &.active{
          a{
            span {
              font-weight: 700;
            }

            i {
              color: var(--primary-color);
            }
          }
        }
        .dropdown {
            button {
                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }
            }
        }
        .notifictionCount {
          top: -3px;
          padding: 0 5px;
          min-width: 23px;
          height: 18px;
          display: flex;
          font-size: 12px;
          color: #707271;;
          font-weight: 600;
          border-radius: 10px;
          position: absolute;
          align-items: center;
          justify-content: center;
          left: calc(50% + 10px);
          background-color: #ffffff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        }
      }
  }

    &.custom-mr {
      @media (max-width: 991px) {
        margin-right: 0;
      }
    }

    .filter-div {
      display: block;

      .filterIcon {
        max-width: 40px;
      }

      .post-dd {
        .btn {
          box-shadow: none;
        }
        .dropdown-menu {
          &.show {
            max-height: 300px;
            overflow: auto;
            
          }
          .loader {
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 1;
          }
        }    
      }

      .MuiBox-root {
        display: flex;
        align-items: center;
        min-width: 300px;
        justify-content: space-between;
        margin-top: 10px;
        margin-bottom: 20px;

      
        &:last-child {
          .right {
            border-bottom: none;
          }
        }
        &:first-child {


         
          margin-bottom: 30px;


        }

        .left {
          float: left;
          margin-right: 20px;

          img {
            width: 40px;
            height: 40px;
            border-radius: 5px;
            border: 1px solid #707271;
          }
        }

        .right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          overflow: hidden;
          padding-bottom: 10px;

          border-bottom: 1px solid $__color-bubble;

          svg {
            max-width: 30px;
            color: $__color-bubble;
            cursor: pointer;
          }
        }

        .lable {
          font-size: 14px;
          margin-right: auto;
          word-break: break-all;
        }
      }
      //  @media(max-width:767) {
      //    display: none;

      //  }
    }

    .btn.mr-15 {
      @media (max-width: 767px) {
        margin-right: 0 !important;
      }
    }
    .inbox-link {
      color: white;
      margin-right: 20px;
      @media (max-width: 767px) {
        display: none;
      }
      i {
        margin-right: 5px;
      }
    }
    
    .notifictionBox{
      @media screen and (max-width: 767px) {
        display: none;
      }
      width: 100px;
      display: flex;
      flex-direction: row;
      .withCircle{
        width: 5px;
      }
      position: relative;


      .notifictionCount{
        top: -8px;
        position: absolute;
        left: 15px;
        width: 23px;
        height: 18px;
        text-align: center;
        background-color: #ffffff;
        border-radius: 10px;
        font-weight: 600;
        font-size: 12px;
        line-height: 1.3;
        padding-top: 2px;
        // padding: 1px;
      }

      .notifications-bell {
        color: white;
        @media (max-width: 767px) {
          display: none;
        }
  
        i {
          margin-right: 0;
        }
        margin-right: 20px;
      }
    }

    
    .dropdown {
      .dropdown-toggle {
        padding: 0;
        margin-left: 100px;
        background-color: transparent !important;
        @media (max-width: 575px) {
          margin-left: 40px;
        }
        i {
          font-size: 40px;
          display: block;
        }
      }
      @media (max-width: 767px) {
        &.post-dd {
          display: block;
        }
      }
    }
  }

  .site-navbar-bottom {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: var(--secondary-color);
    &.whiteNav{
      background-color: white !important;
    }
    .bottom-row{
      width: 100%;
      display: flex;
      padding: 0 40px;
      max-width: 800px;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 575px) {
        padding: 0 20px;
      }
    }
    .addPost {
      .material-icons {
        display: block;
        font-size: 40px;
      }
    }
    .navbar-actions {
      justify-content: flex-start;
      flex-direction: row-reverse;

      .filter-div {
        .dropdown-toggle {
          margin-left: 20px;
        }
      }

      .headerSearch {
        display: flex;
        align-items: center;

        input {
          height: 40px;
          border: 0;
          color: var(--primary-color);
          border-radius: 5px;
          background-color: #ffffff;
        }

        .btn-icon {
          margin-right: 0 !important;
          
          .material-icons {
            margin-right: 0 !important;
            font-size: 40px;
          }
        }
        span{
          i{
            color: white !important;
          }
        }
      }
    }
    
  }
  
  
}

.modal-backdrop {
  z-index: 99999;
}
.modal {
  z-index: 99999;
}

