// ---- spacing helpers inspired by Bootstrap
// m-0 means margin on all sides 0px
// mt-10 means margin top 10px
// px-30 means padding left and right 30px
$spaces: (0, 5, 10, 15, 20, 30);
$sides: (top, bottom, left, right);
$properties: (margin, padding);
$spacer: 1px;
  
@each $property in $properties {
  @each $space in $spaces {
    @each $side in $sides {
      .#{str-slice($property, 0, 1)}#{str-slice($side, 0, 1)}-#{$space} {
        #{$property}-#{$side}: $spacer * $space !important;
      }
    }
    .#{str-slice($property, 0, 1)}x-#{$space} {
      #{$property}-left: $spacer * $space !important;
      #{$property}-right: $spacer * $space !important;
    }
    .#{str-slice($property, 0, 1)}y-#{$space} {
      #{$property}-top: $spacer * $space !important;
      #{$property}-bottom: $spacer * $space !important;
    }
    .#{str-slice($property, 0, 1)}-#{$space} {
      #{$property}-top: $spacer * $space !important;
      #{$property}-bottom: $spacer * $space !important;
      #{$property}-left: $spacer * $space !important;
      #{$property}-right: $spacer * $space !important;
    }
  }
}