@import "../../styles/variables";

.nopadding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.modal-content {
  position: relative;
  .view-group,
  .view-groupmain-content {
    padding: 25px 10px 30px;
    margin: 0 !important;
    .cbtn {
      position: absolute;
      top: 10px;
      right: 5px;
    }
    & .row {
      display: block;
      .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
        .nopadding {
          
        }
        .group-profileCard {
          
          .card-body {
            .add {
              box-shadow: none !important;
              &.flagdiv {
                margin-left: 10px;
                & .flagimg {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }
        .card-footer {
          .row {
            display: flex;
          }
        }
      }

      .col-lg-9,
      .col-lg-8,
      .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
        .group-aboutusCard {
          padding: 10px 0;
          margin-top: 30px !important;
        }

        .group-contactCard {
          margin-bottom: 0 !important;
        }

        .contact-section {
          .d-sm-flex {
            display: block !important;
          }
          .nopadding {
            flex: 0 0 100%;
            max-width: 100%;

            .data-title {
              min-width: 100px;
              font-size: 15px;

              @media (max-width: 767px) {
                min-width: 100px !important;
              }
            }

            .data-value {
              color: var(--primary-color) !important;
              word-break: break-all;
              font-size: 15px;
            }
          }
        }
        .media-section {
          .row {
            .col-lg-12 {
              flex: 0 0 100%;
              max-width: 100%;

              .media-box-cont {
                border: 1px solid rgba(0, 0, 0, 0.125) !important;
                border-radius: 0.5rem;
                margin-top: 10px;
                padding: 10px;
                margin-bottom: 10px;
              }
            }
          }
        }
        .setting-section {
          margin-bottom: 10px;

          .setting-cont {
            border: 1px solid rgba(0, 0, 0, 0.125) !important;
            border-radius: 0.5rem;
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 10px;
            .ctitle {
              padding-bottom: 5px;
              font-size: 15px;
              color: grey;
            }
            .csubtitle {
              font-size: 15px;
              color: black;
            }
            .admin-data {
              display: block;
              .col-md-4,
              .col-md-6 {
                flex: 0 0 100%;
                max-width: 100%;
                padding-bottom: 10px;

                img {
                  width: 25px;
                  height: 25px;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
  }
}

.gdpr-card {
  margin-top: 0 !important;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
}

.back-btn-cont {
  margin-top: 0 !important;
  padding: 20px 40px;
  @media (max-width: 575px) {
    padding: 20px;
  }
}

.group-profileCard {
  border-radius: 0 !important;
  margin-top: 0 !important;

    padding-bottom: 0px!important;
  

  .card-header {
    background-color: var(--primary-color-lighten-5) !important;
    padding: 40px;
    position: absolute;
    width: 100%;
  }
  .verified {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding-top: 10px;
    i {
      font-size: 14px;
      padding-left: 10px;
    }
  }
  .card-body {
    background-color: white !important;
    text-align: center;

    .profile-image {
      border-radius: 5px !important;
      width: 100px;
      height: 100px;
      border: 1px solid var(--primary-color);
      position: relative;
      z-index: 1;
      background: white;
      object-fit: cover;
      margin-bottom: 5px;
    }
    .postcode {
    }
    .grpname {
      font-weight: bold;
      .flagdiv {
        margin-left: 10px;
        .flagimg {
          width: 20px;
          height: 20px;
        }
      }
    }
    .add {
      font-size: 12px;
      .flagdiv {
        margin-left: 5px;
        .flagimg {
          width: 20px;
          height: 20px;
        }
      }
    }
    .createdat {
      color: grey;
      font-size: 10px;
    }
    .newbtn {
      border-radius: 5px !important;
      padding: 10px 60px !important;
    }
  }

  .card {
    padding-bottom: 0px!important;
  }

  .card-footer {
    border-top: 2px solid rgba(0, 0, 0, 0.125) !important;
    padding: 5px;

    .rb {
      border-right: 2px solid rgba(0, 0, 0, 0.125);
      &:last-child {
        border-right: 0;
      }
    }
  }

  .data-label {
    font-weight: bold;
    font-size: 14px;
  }
  .data-value {
    font-weight: bold;
    font-size: 14px;
    text-align: center;

   
  }
  // }
  //   }

  // @media(min-width: 767px) {

  //   display: block;
  // }
}

.profileCard {
  .card-body {
    .ctitle {
      padding-bottom: 15px;
      font-size: 15px;
      color: grey;
    }
    .btn-container {
      width: 100%;
      max-width: 415px;
      min-width: 415px;
      white-space: normal;
      .custom-outlined-btn {
        border: 2px solid #d2d2d2;
        border-radius: 5px;
        padding: 7px 20px;
        padding-left: 45px;
        display: inline-block;
        position: relative;
        min-width: 150px;
        i {
          color: var(--primary-color);
          position: absolute;
          top: 5px;
          left: 15px;
        }

        span {
          color: grey;
          text-transform: capitalize;
          font-weight: normal;
          font-size: 15px;
          display: block;
          text-align: left;
          line-height: 17px;
          color: #404040;
        }
      }
    }
  }
  .aboutme-section {
  }
}
.row {
  .col-lg-8,
  .col-lg-12 {
    .group-aboutusCard {

      border-radius: 0.5rem !important;
      //   padding-top: 10px;
      padding-bottom: 20px;
      padding:10px!important;
      margin-top: 0 !important;
      @media (max-width: 991px) {
        margin-top: 30px !important;
      }
      .h5 {
        padding-top: 10px;
        padding-left: 20px;
      }
      .card-text {
        padding-left: 30px;
        padding-right: 30px;
        font-size: 15px;
      }
    }

    .group-contactCard {
      border-radius: 0.5rem !important;
      margin-bottom: 50px;
      padding: 10px!important;
      @media (max-width: 767px) {
        margin-bottom: 0;
      }
      .contact-heading {
        // padding: 10px 30px;
      }
      .card-body {
        .contact-section {
          padding: 0;
          border: 1px solid rgba(0, 0, 0, 0.125) !important;
          border-radius: 0.5rem;
          padding: 10px;
          .nopadding {
            padding-bottom: 5px!important;
            .data-title {
              min-width: 100px;
              font-size: 15px;

              @media (max-width: 767px) {
                min-width: 100px !important;
              }
            }

            .data-value {
              color: var(--primary-color) !important;
              word-break: break-all;
              font-size: 15px;
            }
          }
        }

        .desc-section {
          padding-top: 10px;
          .card-text {
            border: 1px solid rgba(0, 0, 0, 0.125) !important;
            border-radius: 0.5rem;
            padding: 10px;
          }
        }

        .media-section {
          .heading {
            // padding: 10px;
          }
          .media-box-cont {
            border: 1px solid rgba(0, 0, 0, 0.125) !important;

            border-radius: 0.5rem;
            padding-left: 10px;
            padding-right: 10px;
            margin-top: 10px;
            margin-bottom: 15px;
          }
          .media-box {
            padding: 8px 8px 8px 0px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
            &:first-child {
              border-bottom: 0;
            }
            &:last-child {
              border-bottom: 0 !important;
            }
          }
          .no-data {
            border: 1px solid rgba(0, 0, 0, 0.125) !important;
            border-radius: 0.5rem;
            // padding: 20px;
            margin-top: 10px;
            margin-bottom: 15px;
          }
        }

        .setting-section {
          .setting-cont {
            border: 1px solid rgba(0, 0, 0, 0.125) !important;
            border-radius: 0.5rem;
            margin-top: 10px;
            padding: 10px;

            .ctitle {
              font-size: 15px;
              color: grey;
            }

            .csubtitle {
              font-size: 15px;
              color: black;
            }
            .admin-data {
              img {
                width: 40px;
                height: 40px;
              }
            }
            .admin-per {
              .item {
                padding-bottom: 5px;
              }
            }
          }

          // padding-top: 10px;
          //   .heading {
          //     padding: 10px;
          //   }
        }
      }
    }
  }
}

.spacebw {
  @media (max-width: 767px) {
    padding-bottom: 1.5rem;
  }
}

.person-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card .nav-tabs .nav-link {
  max-width: unset !important;
}

figure.avatar {
  width: 48px;
  height: 48px;
  overflow: hidden;
  box-shadow: none;
  margin: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}