@import "../../../styles/variables";

.grpInfoPage {
  margin-top: 100px;

  .grpInfoTab {
    background-color: white;
    .tab-innerr {
      justify-content: space-between;
      box-shadow: 0 0 15px 0 rgba(85, 84, 84, 0.2);
      .tabss {
        width: 33%;
        border: none;
        height: 50px;
        color: black;
      }
      .active {
        border: none;
        border-bottom: 3px solid var(--primary-color);
        color: var(--primary-color) !important;
      }
    }
  }
}
.grpInfoHeadTites {
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    margin: 0;
    margin-left: 20px;
  }
}
.grpInfoHeadInput {
  padding: 0 10px;
  input {
    padding-left: 15px;
    height: 40px;
//     width: 200px;
    max-width: 300px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgb(71, 70, 70);
    // margin-left: 20px;
    @media (max-width: 575px) {
      max-width: 145px;
    }
  }
}

.editTitleGrpBtns {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  height: 60px;

  justify-content: space-between;
  // align-items: center;
}

.editInfoGrp {
  margin-left: auto;
}


.mainMsgBox {
  display: flex;
  flex-direction: column;

  .bubbleBoxe {
    width: 100%;
    .avatar {
      float: left;
      border: 1px solid #707271;
      border-radius: 5px !important;
      margin-right: 10px;
      margin-top: 12px;
      box-shadow: none;
    }
  }

  .msgDate {
    width: 100% !important;
    text-align: center;
    font-weight: 600;
  }
  .notificationMsg {
    background-color: var(--primary-color);
    color: white;
    font-weight: bold;
    max-width: 400px;
    margin: 0 auto;
    padding: 10px;
    border-radius: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .messageContainer {
    display: flex;
    margin-bottom: 10px;

    .messageInner {
      display: flex;
      max-width: 400px;
      flex-direction: column;
      padding: 10px;
      background-color: #dae3e2;
      border-radius: 20px;
      .name {
        font-size: 15px;
        font-weight: 600;
      }
      p {
        margin: 0;
        font-size: 14px;
      }
      .time {
        margin-left: auto;
        font-size: 12px;
      }
    }
    .myMessageInner {
      background-color: white !important;
    }
    .avatar {
      border: 1px solid #707271;
      border-radius: 5px !important;
      margin-right: 10px;
      margin-top: 5px;
      box-shadow: none;
    }
  }
  .myMessage {
    margin-left: auto;
  }

}