.modal-content {
  &.participants-list-popup {
    padding: 0;
    max-height: 100%;
    z-index: 999999;
    .modal-body {
      padding: 0;
      .close-modal {
        position: absolute;
        top: 15px;
        right: 0px;
        color: #fff;
        &.blue {
          color: var(--primary-color);
          top: 5px;
          right: -10px;
        }
      }

      .manageParticipants {
        .participantsheading {
          background-color: var(--primary-color);
          padding: 15px 30px;
          color: white;
        }
        .iconleftspan {
          margin-right: 10px;
        }
        .scroll {
          overflow: auto;
          max-height: calc(600px - 150px);
        }

        .participantsList {
          display: flex;
          flex-direction: column;
          min-height: 350px;
          max-height: 450px;
          overflow: auto;
          // padding: 15px 30px;
          .participantsBox {
            display: flex;
            align-items: center;
            padding: 15px 40px;
            // margin-bottom: ;
            border-bottom: 1px solid #eee;
            img {
              width: 45px;
              height: 45px;
              object-fit: cover;
              border-radius: 5px;
              margin-right: 20px;
              border: 1px solid #707271;
            }
            .admin {
              p {
                margin: 0;
                font-size: 13px;
                color: #484848;
              }
            }
            .participantName {
              font-size: 16px;
              color: #484848;
              font-weight: bold;
            }
            button {
              margin-left: auto;
              span {
                margin: 0;
                i {
                  margin: 0;

                  font-size: 26px;
                }
              }
            }
          }
        }
      }

      .addParticipants {
        .participantsheading {
          background-color: var(--primary-color);
          padding: 15px 30px;
          color: white;
        }

        .added-usersBox{
            padding: 5px 30px;

            .added-users {
                display: flex;
                align-items: center;
                height: 85px;
                overflow-y: auto;
                .img-cont {
                  position: relative;
                  img {
                    width: 50px;
                    height: 50px;
                    object-fit: cover;
                    border-radius: 5px;
                    margin-right: 20px;
                    border: 1px solid #707271;
                  }
                  i {
                    position: absolute;
                    right: 10px;
                    bottom: -10px;
                    font-size: 20px;
                    cursor: pointer;
                  }
                }
              }
        }



        .iconleftspan {
          margin-right: 10px;
        }
        .scroll {
          overflow: auto;
          max-height: calc(600px - 150px);
        }
        .addParticipantsList {
          display: flex;
          flex-direction: column;
          max-height: 300px;
          overflow: auto;
          .participantsBox {
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 15px 40px;
            // margin-bottom: ;
            border-bottom: 1px solid #eee;
            img {
              width: 45px;
              height: 45px;
              object-fit: cover;
              border-radius: 5px;
              margin-right: 20px;
              border: 1px solid #707271;
            }

            .participantName {
              font-size: 16px;
              color: #484848;
              font-weight: bold;
            }

            i {
              color: var(--secondary-color);
              margin-left: auto;
            }
          }
        }
        .btn-cont{
          width: 100%;
          display: flex;
          margin-bottom: 10px;
          justify-content: center !important;
        }

      }
    }
  }
}


.addParty{
  color:var(--primary-color)
}

.imageText{
  margin-left: -5px;
  max-width: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
}