@import "../../styles/variables";

.group-members{
    .group-members-actions{
        background-color: red;
        color: blue;
        padding: 100;
        flex-direction: row;
    }
}

.place-holder{
    margin-bottom: 100px;
}

.group-title{        
    top: var(--header-height);
    position: fixed;
    z-index: 999;
    left: 0;
    width: 100%;
    padding: 0;
    border-bottom: 0;
    margin-bottom: 0;
    
    .avatar-title{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;    
        position: relative;            
        width: 100%;
        margin: 0 auto;
        padding: 20px 40px;
        background-color: white;
        max-width: 600px;
        @media (max-width: 575px) {
            padding: 20px;
        }
        .avatar {
            box-shadow: none;
            border: 1px solid #707271;
            border-radius: 5px !important;
        }
        h2{
            flex: 1;
            margin: 0;
            font-weight: bold;
            margin-left: 20px;
            @media(max-width:376px) { 
                font-size: 15px;
            }
            
        }

        .actions {
            display: flex;
            align-items: center;
        }

        .delete-btn {
            @media(max-width:767px) {
                .btn-icon {
                    .iconleftspan {
                        // font-size: initial;
                        font-size: 0;
                    }
                }
            }

            @media(max-width:575px) { 
                right: 20px;
            }
        }
    }
}

.showAlert {
    .alertMeg {
        position: fixed;
        top: 60px;
        left: 0;
        padding: 15px 0;
        margin: 0;
        width: 100%;
    }
    .group-title {
        top: 120px;
        @media (max-width: 575px) {
            top: 135px;
        }
    }
}


.feed.main-content {
    .group-title {
        position: inherit;
    }
    .place-holder {
        margin-bottom: 5px!important;
    }
}