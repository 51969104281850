#multiselectContainerReact {
  max-width: 230px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
}

// .optionListContainer {
//   max-height: 100px !important;
// }

// .optionContainer{
//   display: block !important;
// }

// .forOPenHide{
//   .Dv7FLAST-3bZceA5TIxEN {
//     display: block !important;
//   }
// }

// .Dv7FLAST-3bZceA5TIxEN {
//   display: block;
// }


._1YkOwrSkIvJIAvNNRNP3P5 {
  // display: block;
}

._3vLmCG3bB3CM2hhAiQX1tN {
  padding-bottom: 50px!important;
}

.invitediv {
  display: flex;
  align-items: center;
  @media (max-width: 991px) {
    padding-left: 6px;
  }

  .invite-btn {
    padding-left: 20px;
    @media (max-width: 991px) {
      margin-left: auto;
    }
  }
}

.invite-select {
  @media (max-width: 991px) {
    order: 1;
    width: 100%;
  }
}
#multiselectContainerReact {
  @media (max-width: 991px) {
    order: 2;
  }
}
.custom { display: none; }