@import "../../../styles/variables";

.user-card,
.user-cardread,
.user-cardunread {
  padding: 20px 40px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 575px) {
    padding: 20px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid $__color-border;
  }

  .user-card-content {
    display: flex;
    align-items: center;
    overflow: hidden;
    .avatar {
      border: 1px solid #707271;
      border-radius: 5px !important;
      margin-right: 20px;
      box-shadow: none;
      cursor: pointer;

      color: white !important;
      text-transform: uppercase;

      // border-radius: 5px;
      // margin-right: 20px;
      // flex: none;
      @include break-sm {
        width: 45px !important;
        height: 45px !important;
      }
    }
    > div {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
    span {
      line-height: 1.3;
      word-break: break-word;
    }
    .bold {
      margin-bottom: 5px;

      max-width: 220px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      // font-size: 16px;
    }
  }
  .user-card-actions {
    display: flex;
    align-items: center;
    flex: none;
    @include break-md {
      flex-direction: column;
      margin-left: 15px;
    }
    button,
    a {
      margin-left: 30px;
      @include break-md {
        padding: 5px 0;
      }
      > span {
        color: var(--primary-color);
      }
    }
    .link {
      @media (max-width: 767px) {
        margin-bottom: 15px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  // img{
  //     width: 60px;
  //     height: 60px;
  //     object-fit: cover;
  //     border-radius: 5px;
  //     margin-right: 20px;
  //     border: 1px solid #707271;
  //     flex: none;
  // }

  .notification-data {
    .title {
      font-weight: bold;
      font-size: 16px;
      line-height: 1.3;
      padding-top: 0;
      padding-left: 0 !important;
    }

    .message {
      font-size: 16px;
    }

    .date {
      font-size: 12px;
    }
  }
}

.followers,
.favorites {
  .user-card-actions {
    min-width: 90px;
    align-items: flex-end;
    a {
      @include break-md {
        margin-bottom: 30px;
      }
    }
  }
}
