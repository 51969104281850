@import "../../../styles/variables";



.modal-content ,.editgroupcompo {
  max-width: 800px;
  margin: 0 auto;
  // margin-bottom: 50px;
  // margin-top: 40px;
  padding: 20px 40px;
  @media (max-width: 575px) {
    padding: 20px;
  }
  .close-modal {
    margin-left: auto;
  }

  h3 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    > div {
      flex: 1 1 auto;
    }
    button {
      flex: 1;
    }
    button.flex-none {
      flex: 0 0 auto;
    }
  }

  .row-form-control {
    display: flex;
    align-items: center;

    .avatar-box {
      width: 100px;
      height: 100px;
      margin-right: 20px;
      background-color: #f7f7f7;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .upload-list {
      border-bottom: none;
      .material-icons {
        margin: 0;
        font-size: 40px;
      }
      .btn-icon {
        .material-icons {
          font-size: 20px;
        }  
      }
    }

    .single-media {
      padding: 10px;
      img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
      }
    }
  }

  .group-admins {
    background-color: $__color-body;

    .customCheckbox {
      margin-bottom: 15px;
      margin-top: 0;
    }

    .ps {
      padding: 10px;
    }
  }
}

.upload-list {
  border-bottom: 1px solid $__color-border;
}
