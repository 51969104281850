@import "../../styles/variables";

.my-profile {
  .formMessage {
    text-align: left;
    padding-bottom: 15px;
  }
}

.my-profile-box {
  background-color: white;
  .my-profile-header, 
  .my-profile-content {
    padding: 20px 40px;
    @media (max-width: 575px) {
      padding: 20px;
    }
  }
  .my-profile-header {
    border-bottom: 1px solid $__color-border;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .upload-list {
    .single-media {
      img {
        margin-right: 0;
      }
    }
    border: none;
  }

  .my-profile-content {
    .form-group span,
    .form-check-label {
      font-size: 16px;
      color: var(--primary-color);
    }

    .form-group span {
      display: block;
      &.aboutme {
        word-break: break-all;
      }
    }

    img {
      width: 60px;
      height: 60px;
      border-radius: 5px;
      object-fit: cover;
      margin-right: 20px;
      border: 1px solid #707271;
    }

    .name {
      font-size: 16px;
      font-weight: 600;
      color: var(--primary-color);
    }

    .name-group {
      display: flex;
      align-items: center;
      .name-form-group {
        display: flex;
        width: calc(100% - 110px);
        margin-left: 20px;
        .form-group {
          width: 50%;
          &:first-child {
            padding-right: 20px;
            @include break-sm {
              padding-right: 5px;
            }
          }
          &:last-child {
            padding-left: 20px;
            @include break-sm {
              padding-left: 5px;
            }
          }
        }
      }
    }
  }

  .profile-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button[type="submit"] {
      margin-left: 50px;
      min-width: 180px;
    }
  }

  .deleteAccount {
    margin-top: 10px;
    margin-left: -5px;
  }
}
