@import "../../../styles/variables";

.btn,
.link.link-btn {
  border: none;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  box-shadow: unset;
  color: white;
  padding: 10px 30px;
  border-radius: 30px;
  font-weight: bold;
  font-size: 14px;
  transition: 0.3s;
  line-height: 1.5;

  &.invisible {
    display: none;
  }

  &:hover {
    color: white;
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
  }

  &.btn-clear {
    padding: 0;
    border: none;
    background: transparent;
    color: var(--primary-color);
    line-height: 1;
    box-shadow: none;

    &:hover {
      color: var(--secondary-color);
      background-color: transparent;
    }
  }

  &.btn-active {
    color: var(--secondary-color);
  }

  &.inverted {
    border-color: white;
    color: var(--primary-color);
    background-color: white;
  }

  &.green-border {
    border: 2px solid var(--primary-color);
  }
}

.link {
  text-decoration: none !important;
  color: var(--primary-color);
  line-height: 1;

  span {
    font-size: 16px;
    font-weight: 500;
  }

  &:hover {
    color: var(--secondary-color);
    background-color: transparent;
  }

  &.icon-link {
    display: flex;
    align-items: center;

    i {
      margin-right: 15px;
      font-size: 20px;
    }
  }

  &.link-active {
    color: var(--secondary-color) !important;
  }
}

.link-disabled {
  color: $__color-disabled;

  span {
    color: $__color-disabled;
  }
}

.icon-mr-0 {
  i {
    margin-right: 0 !important;
  }
}

.btn-icon {
  display: flex;
  align-items: center;

  i {
    margin-right: 10px;

    @include break-md {
      margin-right: 0px;
    }
  }
}

.icon-btn-clear {
  padding: 0;
  border: none;
  background: transparent;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  box-shadow: none;

  span {
    padding-right: 0;
  }

  i {
    margin-right: 5px;
    font-size: 20px;
    z-index: 99;
  }

  &:hover {
    color: var(--secondary-color);
    background-color: transparent;
  }
}

.newPostBtnHolder {

  button:first-child,
  a:first-child {
    top: 69px;
    padding: 0;
    opacity: 0;
    width: 40px;
    height: 40px;
    z-index: 99999;
    position: fixed;
    left: calc(50% - 360px);

    @media (max-width: 800px) {
      left: 40px;
    }

    @media (max-width: 575px) {
      left: 20px;
    }
  }

  button:nth-child(2),
  a:nth-child(2) {
    top: 72px;
    padding: 0;
    // opacity: 0;
    font-size: 0;
    width: 35px;
    height: 35px;
    z-index: 99999;
    position: fixed;
    box-shadow: none;
    border: 3px solid #ffffff;
    background-color: transparent;
    left: calc(50% - 310px);

    i {
      font-size: 22px;
      margin-right: 0;
    }

    @media (max-width: 800px) {
      left: 90px;
    }

    @media (max-width: 575px) {
      left: 70px;
    }
  }
}

.groupPortalBtnHolder {

  button,
  a {
    position: fixed;
    top: 150px;
    right: 25px;

    @include break-md {
      padding: 10px;
      top: auto;
      bottom: 170px;

      span {
        display: none;
      }
    }
  }
}

.emoji-size {
  &.icon-btn-clear i {
    font-size: 30px !important;
  }
}

.iconrightspan {
  padding-left: 5px;

  i {
    display: block;
  }
}

.nowidth {
  .iconleftspan {
    width: auto;
  }
}

.iconleftspan {
  padding-right: 5px;

  i {
    display: block;
  }
}

.reverseicon {
  .iconrightspan {
    i {
      transform: rotateY(180deg);
    }
  }

  i {
    transform: rotateY(180deg);
  }
}

.toggle-btn {
  padding-left: 20px;
  height: 25px;

  &.visiblehidden {
    visibility: hidden;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 25px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: gray;

    -webkit-transition: .4s;
    transition: .4s;
  }




  input:checked+.slider {
    background-color: $__color-bubble;
  }

  input:focus+.slider {
    box-shadow: 0 0 1px var(--primary-color);
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
    background-color: var(--primary-color);
  }



  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

}

.btn-outline-primary {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-color: #fff;
  box-shadow: unset;

  &:hover {
    color: var(--primary-color);
    border-color: var(--primary-color);
    background-color: var(--primary-color-lighten-5);
  }
  
  &:active {
    color: #fff;
    border-color: var(--primary-color) !important;
    background-color: var(--primary-color) !important;
  }

  &.btn-active {
    color: var(--primary-color);
  }

  &.w-100 {
    margin: 0 auto;
  }
}

.btn-secondary {
  background-color: var(--secondary-color);

  &.w-100 {
    position: relative;

    i {
      position: absolute;
      top: 8px;
      right: 10px;
    }
  }
}

.btn {
  &.w-100 {
    max-width: 343px;
  }
}